import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../components/layout.scss";
import Video from "../components/Video";
import Layout from "../components/layout";
import { updateMe } from "../api/users";
import { getUserQuiz } from "../api/quiz";
import Auth from "../components/Auth";
import Loading from "../components/Loading";
import ProgramProgressSection from '../components/programProgressSection';


export const query = graphql`
{
  static: allContentfulWelcomeToProgram {
    nodes {
      buttonText
      nextStepTitle
      subHeading
      title
      message {
        message
      }
      nextStepMessage {
        nextStepMessage
      }
      video {
        file {
          url
        }
      }
      videoPlaceholder {
        fluid {
          src
        }
      }
    }
  }
  categories: allContentfulActionCategories {
    nodes {
      title
      questionIDs
      order
      durationInMins
      programDurationMessage {
        programDurationMessage
      }
      programFeaturesDescription {
        programFeaturesDescription
      }
    }
  }
  lessons: allContentfulLessons {
    nodes {
      lessonId
      lessonName
      lessonDescription {
        lessonDescription
      }
      tip1Title
      tip1Description {
        tip1Description
      }
      tip2Title {
        tip2Title
      }
      tip2Description {
        tip2Description
      }
      tip3Title
      tip3Description {
        tip3Description
      }
      videoName
      videoPlaceholderImage {
        fluid {
          src
        }
      }
      cloudinaryVideo {
        secure_url
        original_url
      }
      practiceChild
      correctPracticeLevels
      failedMessage {
        failedMessage
      }
    }
  }  
}
`;

function WelcomeToProgram({ data }) {
  const { static: { nodes } } = data;
  const {
    title,
    buttonText,
    subHeading,
    nextStepTitle,
    video: { file: { url } },
    message: { message },
    nextStepMessage: { nextStepMessage },
    videoPlaceholder: { fluid: { src } },
  } = nodes[0];

  const [isLoading, setLoading] = useState(true);
  const [preselectedCardIndex, setPreselectedCardIndex] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [userAnswers, setUserAnswersState] = useState({});
  const {
    categories: { nodes: categories },
    lessons: { nodes: lessons },
  } = data;

  const navigateTo = async () => {
    await updateMe({ hasVisitedProgram: true });
    window.location.href = '/toddler-talk-plan'
    // window.location.href = `/program?lessonId=${selectedQuestion['lessonId']}`
  }

  const lessonCategories = categories
    .sort((a, b) => a.order - b.order)
    .map(category => ({
      title: category.title,
      order: category.order,
      lessons: category.questionIDs.map(
        questionId =>
          lessons.find(lesson => lesson.lessonId === questionId) || {}
      ),
    }));

  const sortedLessons = lessonCategories
    .map(item => item.lessons)
    .reduce((prev, next) => prev.concat(next));

  useEffect(async () => {
    try {
      setLoading(true);
      const { userAnswers, hasVisitedProgram } = await getUserQuiz();
      if (hasVisitedProgram) {
        // window.location.href = '/program-progress'
        window.location.href = '/toddler-talk-plan'
      } else {
        let newUserAnswers = {};
        userAnswers.forEach(answer => {
          newUserAnswers[answer.questionId] = {
            answers: answer.answers,
            result: answer.result,
          };
        });
        setUserAnswersState(newUserAnswers);
        let item = sortedLessons.find(
          lesson =>
            !newUserAnswers[lesson.lessonId] ||
            (newUserAnswers[lesson.lessonId] &&
              !newUserAnswers[lesson.lessonId].result)
        ) || sortedLessons[0];

        let lessonId = item['lessonId'];
        let index = categories.findIndex(data => data['questionIDs'].includes(lessonId));
        if (index > -1) {
          setPreselectedCardIndex(index)
          setSelectedQuestion(item)
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  if (isLoading) return <Loading />;

  return (
    <Auth>
      <Layout>
        <div id="content" className="site-content">
          <div className="content-inner">
            <div className="">
              <div className="row content-row">
                <div id="primary" className="content-area col-12">
                  <main id="main" className="site-main">
                    <article
                      id="post-45"
                      className="post-45 page type-page status-publish hentry"
                    >
                      <div className="entry-content clearfix">
                        <div
                          data-elementor-type="wp-page"
                          data-elementor-id="45"
                          className="elementor elementor-45"
                          data-elementor-settings="[]"
                        >
                          <div className="elementor-section-wrap mt-5 mb-5">
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                              data-id="f6893e0"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched"}'
                            >
                              <div className="elementor-container elementor-column-gap-default ">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                  data-id="9f5f77a"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                      data-id="4522805"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default ">
                                        <div
                                          className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                          data-id="367b21f"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                              data-id="9265126"
                                              data-element_type="widget"
                                              data-widget_type="cms_image_single.default"
                                            >
                                              <div className="elementor-widget-container animation-top">
                                                <div className="cms-image-single cms-animation-left-to-right ">
                                                  <div className="cms-img-box">
                                                    <StaticImage
                                                      placeholder="none"
                                                      width="113"
                                                      height="117"
                                                      src="../images/image-home-2.png"
                                                      className="attachment-full size-full"
                                                      alt=""
                                                      loading="lazy"
                                                    />{" "}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                          data-id="8869f2a"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                              data-id="8832270"
                                              data-element_type="widget"
                                              data-widget_type="cms_heading.default"
                                            >
                                              <div className="elementor-widget-container payment-page-widget-container">
                                                <div className="cms-heading-wrapper cms-heading-layout1 wow pulse p-3">
                                                  <div className="custom-subheading">
                                                    {subHeading}
                                                  </div>
                                                  <h3 className="custom-heading">
                                                    <span>Welcome To {localStorage.getItem("childName") ? localStorage.getItem("childName") + "'s" : "Child's"} Program</span>
                                                  </h3>
                                                  <div className="custom-heading-description mb-4 p-4">
                                                    {message}
                                                  </div>

                                                  <Video
                                                    videoSrcURL={url}
                                                    poster={src} />
                                                  <br />
                                                  <br />
                                                  <button
                                                    class="btn lesson-plan-btn mt-4"
                                                    onClick={navigateTo}
                                                    target='_blank'
                                                    style={{ borderRadius: "0px" }}
                                                  >
                                                    <span className="lesson-plan-btn-text">
                                                      Continue To Your Lesson Plan
                                                    </span>
                                                  </button>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="b410ac4"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                        data-id="0b4fe1f"
                                        data-element_type="column"
                                      ></div>
                                    </div>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>


                          {/* <ProgramProgressSection 
                          dataProp={data} 
                          enableDirectQueOpening={false}
                          selectedQuestionProp={selectedQuestion}
                          userAnswersProp={userAnswers}
                          preselectedCardIndexProp={preselectedCardIndex} />
                           */}
                          {/* <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          ></div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="container-center" style={{width: '100%'}}>
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                <h3 className="custom-heading">
                                                  <span>
                                                   {nextStepTitle}
                                                  </span>
                                                </h3>

                                                <p className="text-left">
                                                  {nextStepMessage}
                                                </p>
                                                <button
                                                  class="btn lesson-plan-btn mt-4"
                                                  onClick={navigateTo}
                                                  target='_blank'
                                                  >
                                                    <span className="lesson-plan-btn-text">
                                                      {buttonText}{" "}
                                                    </span>
                                                  </button>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div> */}

                        </div>
                      </div>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Auth>
  );
}

export default WelcomeToProgram;
